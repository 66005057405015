<template>
  <div>
    <v-col
      class="text-right"
    >
      <v-btn
        color="#000"
        @click="showForm = true"
      >
        <v-icon class="mr-2">
          mdi-plus-circle-outline
        </v-icon>
        Create New
      </v-btn>
    </v-col>
    <v-tabs
      v-model="tab"
      class="questionnaire-tabs"
    >
      <v-tab
        v-for="item in items"
        :key="item.tab"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      class="my-tabs-background-style"
      touchless
    >
      <v-tab-item
        v-for="item in items"
        :key="item.tab"
      >
        <component :is="item.content" />
      </v-tab-item>
    </v-tabs-items>
    <create-questionnaire
      v-if="showForm"
      @closed="showForm = false"
      @success="getAllQuestionnaires"
    />
    <centre-spinner
      :loading="loading"
    />
  </div>
</template>

<script>
  import ActiveQuestionnaires from './ActiveQuestionnaires.vue';
  import DeletedQuestionnaires from './DeletedQuestionnaires.vue';
  import CreateQuestionnaire from 'src/views/dashboard/settings/CreateQuestionnaire.vue';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'Questionnaire',

    components: {
      ActiveQuestionnaires,
      DeletedQuestionnaires,
      'create-questionnaire': CreateQuestionnaire,
      'centre-spinner': spinner,
    },
    data: () => ({
      tab: null,
      items: [
        { tab: 'Active', content: ActiveQuestionnaires },
        { tab: 'Deleted', content: DeletedQuestionnaires },
      ],
      showForm: false,
      loading: false,
    }),
    async mounted () {
      await this.getAllQuestionnaires();
    },
    methods: {
      async getAllQuestionnaires () {
        this.loading = true;
        await this.$store.dispatch('featuresettings/fetchAllQuestionnaire').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.title-style {
  margin-left: 30px;
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-tabs-background-style {
    background-color: #fff !important;
}
.questionnaire-tabs {
  margin-left: 0px;
}
.v-card {
  margin-top: 0px!important;
}
.v-card__text{
  padding: 30px!important;
}
</style>
