<template>
  <div>
    <v-card
      color="#fff"
      class="project-list-card mt-0 "
    >
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="questionsTable.headers"
        :items="questionnaireList"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        disable-sort
        class="elevation-1 clickable table-projects"
        mobile-breakpoint="100"
        :search="search"
      >
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr>
            <td>{{ item.name }}</td>
            <td>{{ item.question }}</td>
            <td>
              <span
                v-if="item.user_type !== null"
                class="type-style"
              > {{ item.user_type | arrangePretty }} </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>
              {{ item.created_at | convertToLocal }}
            </td>
            <td>
              <font
                :color="getColor(item.deleted_at)"
                class="font-my-style"
              >
                {{ item.deleted_at | statusUpdate }}
              </font>
            </td>
            <td>
              <v-btn
                icon
                small
                @click.stop
                @click="expand(!isExpanded)"
              >
                <v-icon
                  v-if="!isExpanded"
                >
                  mdi-arrow-down-drop-circle
                </v-icon>
                <v-icon v-if="isExpanded">
                  mdi-arrow-up-drop-circle
                </v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                @click.stop
                @click="deleteQuestion(item.id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-card>
              <v-toolbar
                color="#37474F"
                dark
              >
                <v-toolbar-title class="my-tool-bar-title-style">
                  Answer Options
                </v-toolbar-title>
              </v-toolbar>
              <v-list>
                <v-list-item-content class="ml-3">
                  <v-list-item-title class="my-title-style">
                    <template
                      v-for="(ans, i) in item.answers"
                    >
                      <span
                        :key="i"
                        class="option-style"
                      >
                        Option {{ i + 1 }}
                        -<v-chip
                          color="#FF3700"
                          outlined
                          class="ml-2"
                        >
                          {{ ans.answer }}
                        </v-chip>
                        ,
                      </span>
                    </template>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list>
            </v-card>
          </td>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <delete-questionnaires
      v-if="showDeleteQuestionnaire"
      :title="'Do you want to delete this questionnaire?'"
      @closed="showDeleteQuestionnaire = false"
      @confirmed="deleteQuestionnaireConfirmed()"
    />
  </div>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import DeleteQuestionnaire from 'src/views/dashboard/component/DeleteDialog';
  import Constants from 'src/constants';

  export default {
    name: 'Questionnaire',
    components: {
      'centre-spinner': spinner,
      'delete-questionnaires': DeleteQuestionnaire,
      },
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      statusUpdate (val) {
        if (val !== null) {
          return 'Deleted';
        }
        return 'Active*';
      },
      arrangePretty (val) {
  if (Array.isArray(val)) {
    return val.map(type => type === 'staff' ? 'Site Manager' : type).join(', ');
  }
  return val;
},

    },
    data () {
      return {
        showForm: false,
        loading: false,
        questions: [],
        expanded: [],
        singleExpand: true,
        questionsTable: {
          headers: [
            { text: 'Name', align: 'left', value: 'name' },
            { text: 'Question', value: 'question' },
            { text: 'Type', value: 'type' },
            { text: 'Created At', value: 'type' },
            { text: 'Status', value: 'updated_at' },
            { text: 'Answers', value: 'data-table-expand' },
            { text: 'Delete', value: 'delete' },
          ],
        },
        showDeleteQuestionnaire: false,
        deleteId: '',
        search: '',
      };
    },
    computed: {
      questionnaireList () {
        const fetchedQuestionnaires = this.$store.getters['featuresettings/getQuestionnaireList'];
        const filtredQuestionnaires = fetchedQuestionnaires.filter(i => i.deleted_at === null);
        return filtredQuestionnaires;
      },
    },
    methods: {
      async getAllQuestionnaires () {
        this.loading = true;
        await this.$store.dispatch('featuresettings/fetchAllQuestionnaire').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      deleteQuestion (QuestionnaireId) {
        this.showDeleteQuestionnaire = true;
        this.deleteId = QuestionnaireId;
      },
      deleteQuestionnaireConfirmed () {
        this.showDeleteQuestionnaire = false;
        this.loading = true;
        this.$store.dispatch('featuresettings/removeQuestionnaire', {
          QuestionnaireId: this.deleteId,
        }).then(response => {
          this.loading = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Questionnaire deleted Successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.deleteId = null;
          this.getAllQuestionnaires();
        }).catch(() => {
          this.showDeleteQuestionnaire = false;
          this.deleteId = null;
          this.loading = false;
        });
      },
      editQuestionnaire () {
        this.$store.dispatch('alert/onAlert', {
          message: 'You are not authorized to perform this action.',
          type: Constants.ALERT_TYPE_INFO,
        });
      },
      getColor (val) {
        if (val !== null) {
          return '#C62828';
        }
        return '#2E7D32';
      },
    },
  };
</script>

<style scoped>
  .v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table {
  background-color: #ECEFF1;
}
.my-toolbar-style {
  color: #37474F;
  font-weight: bold;
}
.my-tool-bar-title-style {
 font-size: 18px;
}
.my-toolbar-style {
  padding-left: 0px;
}
.strong-style {
  color: #37474F;
  font-weight: bold;
}
.my-title-style {
  font-size: 14px !important;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.option-style {
  color: #37474F;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}
.font-my-style {
  font-weight: bold;
}
.type-style {
  text-transform: capitalize;
}
.v-card {
    margin-top: 10px!important;
  }
  .table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
}
</style>
